.paddingall{
    padding: 10px 10px 10px 10px;
}
.pricetitle{
    font-weight: 600;
}
.privesubtitle{
    font-size: 14px;
}
.iconstyles{
    color: aliceblue;
}
.iconstylesblack{
    color: black;
}
.gradient{
    background: rgb(40, 34, 70);
    background: linear-gradient(158deg, rgba(40, 34, 70, 1) 0%, rgba(30, 47, 141, 1) 100%);
}
.gradientlight{
    background: rgb(53, 138, 148);
    background: linear-gradient(158deg, rgba(53, 138, 148, 1) 0%, rgba(91, 180, 96, 1) 100%);
}
.bgcolor{
    min-height: 100vh;
    background: #d9e3f0;
}
.chart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}