.book-container {
    width: 100%;
    max-width: 100%; /* Giới hạn chiều rộng của BookList */
    margin: 0 auto; /* Căn giữa BookList */
    background-color: #ffffff; /* Màu nền */
    border-radius: 10px; /* Độ cong viền */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Đổ bóng */
    padding: 20px;
  
    @media only screen and (max-width: 1200px) {
      max-width: 800px; /* Giảm chiều rộng khi màn hình nhỏ hơn */
    }
  
    @media only screen and (max-width: 900px) {
      max-width: 700px; /* Giảm chiều rộng khi màn hình nhỏ hơn */
    }
    
    @media only screen and (max-width: 800px) {
      max-width: 500px; /* Giảm chiều rộng khi màn hình nhỏ hơn */
    }

    @media only screen and (max-width: 600px) {
      max-width: 300px; /* Giảm chiều rộng khi màn hình nhỏ hơn */
    }

    @media only screen and (max-width: 400px) {
      max-width: 250px; /* Giảm chiều rộng khi màn hình nhỏ hơn */
    }
  }
  
  .book-table {
    width: 100%;
    overflow-x: auto;
  }
  .book-table-cell {
    min-width: 100px;
  }
  .book-image {
    width: 50px;
    height: auto;
  }
  .pointer {
    cursor: pointer;
  }