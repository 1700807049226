.error{
    input{
        border-color: red;
    }
    input::placeholder {
        color: red;
    }
}
.left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    flex-direction: column;
    color: white;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    // align-items: center;
    background-color: white;
    width: 30%;
    // /* margin: 0 90px; */
    // /* padding: 20px 30px; */
    height: 80%;
    // /* justify-content: space-around;
    margin-right: 50px;

    h2 {
        margin-left: 30px;
        margin-top: 30px;
        background-color: white;
        /* margin-right: 10px; */
        height: 15%;
    }

    form {

        display: flex;
        flex-direction: column;
        background-color: white;
        height: 85%;
        align-items: center;
        width: 100%;

        .username,
        .password {
            position: relative;
            height: 10%;
            margin: 10px 0;
            width: 80%;
            margin-bottom: 40px;
            margin-right: 10px;
            .username_header,
            .password_header {
                position: absolute;
                font-size: 12px;
                top: -7px;
                background-color: white;
                left: 15px;
            }



            input {
                width: 100%;
                height: 100%;
                
            }
            input
            {
                padding-left: 10px;
            }
            a{
                font-size: 13px;
            }
            input[type="password"] {
                margin-bottom: 3px;
            }
        }
        .btn_login {
            height: 10%;
            width: 50%;
            margin-top: 20px;
            margin-bottom: 40px;
            background-color: #9C90D0;
            border: #9C90D0;
            font-size: 16px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
            color: white;
        }
        .hr{
            position: relative;
            width: 80%;
            margin-bottom: 40px;
            .or{
                position: absolute;
                top: -9px;
                left: 47%;
                background-color: white;
            }
        }
        .register{
            display: flex;
        }
    }
}