@use "sass:map";
@import "../../styles/pages/theme";
@import "../../styles/pages/responsive";
.orderpage_header {
    margin-top: 10px;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid black;

    span {
        width: 10%;
        margin-right: 20px;
        text-align: center;
    }
}

.book_detail,
.payment_method,
.button_box,
.price_box {
    display: flex;
    flex-direction: row;
    background-color: white;
}

.list_book {

    padding-bottom: 10px;
    padding-top: 10px;
    background-color: white;
    border-bottom: 1px solid black;
}

.book_detail:not(:last-child) {
    padding-bottom: 10px;
}

.book_detail {
    width: 100%;

    .img {
        width: 10%;
        height: 140px;
        margin-left: 20px;
        margin-right: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .book_detail_text {
        width: 70%;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .price,
    .quantity {
        span {
            width: 100%;
            text-align: center;
        }

        width: 10%;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.price_box {
    padding: 20px 0;
    font-size: larger;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    width: 50%;
    div{
        margin-right: 10px;
    }
}

.payment_method {
    padding: 20px 0;
    width: 50%;
    margin-left: 20px;

    div:not(:last-child) {
        margin-right: 20px;
    }
}

.button_box {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    height: 50px;
    margin-bottom: 10px;

    .button_cancel{
        border: 1px solid map-get($map: $theme-color, $key: 'main');
        width: 8%;
        margin-right: 10px;
        background-color: white;
        color: map-get($map: $theme-color, $key: 'main' );
    }
    .button_order {
        width: 8%;
        margin-right: 10px;
        border: none;
    }

    .button_order {
        background-color: #9C90D0;
        color: white;
    }
}

.box_payment_price {
    display: flex;
    width: 100%;
    background-color: white;
}