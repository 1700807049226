@use "sass:map";
@import "theme";
@import "responsive";

html,
body {
    margin: 0;
    background-color: #d9e3f0;
    width: 100%;
}

* {
    font-family: sans-serif;
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1170px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.button-submit {
    font-size: 14px;
    color: #ffffff;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    padding: 17px 30px 17px;
    background-color: map-get($map: $theme-color, $key: "main" );
}
@include Responsive($DESKTOP) {
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

}
@include Responsive($MEDIUM_DEVICE) {
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}
@include Responsive($TABLET_DEVICE){
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
        height: 100%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@include Responsive($WIDE_MOBILE){
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@include Responsive($SMALL_DEVICES){
    .col-xs-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
