.left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    flex-direction: column;
    color: white;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    // align-items: center;
    background-color: white;
    width: 30%;
    // /* margin: 0 90px; */
    // /* padding: 20px 30px; */
    height: 80%;
    // /* justify-content: space-around;
    margin-right: 50px;

    h2 {
        margin-left: 30px;
        margin-top: 30px;
        background-color: white;
        /* margin-right: 10px; */
        height: 8%;
    }

    form {

        display: flex;
        flex-direction: column;
        background-color: white;
        height: 92%;
        align-items: center;
        width: 100%;

        .lastname,
        .firstname,
        .email,
        .password,
        .passwordconfirm {
            position: relative;
            height: 10%;
            margin: 10px 0;
            width: 80%;
            margin-bottom: 20px;
            margin-right: 10px;
            .lastname_header,
            .firstname_header,
            .email_header,
            .password_header,
            .passwordconfirm_header {
                position: absolute;
                font-size: 12px;
                top: -7px;
                background-color: white;
                left: 15px;
            }



            input {
                width: 100%;
                height: 100%;
                
            }
            input
            {
                padding-left: 10px;
            }
            a{
                font-size: 13px;
            }
            input[type="password"] {
                margin-bottom: 3px;
            }
        }
        button {
            height: 10%;
            width: 50%;
            margin-bottom: 20px;
            background-color: #9C90D0;
            border: #9C90D0;
            font-size: 16px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
            color: white;
        }
        .hr{
            position: relative;
            width: 80%;
            margin-bottom: 20px;
            .or{
                position: absolute;
                top: -9px;
                left: 47%;
                background-color: white;
            }
        }
    }
}

.error{
    border-color: red;
}